import { ErrorPage } from 'ui'
import { getPageGlobalData } from 'utils/formatPageData'

const Custom404Page = () => {
	return <ErrorPage errorNum={404} />
}

export default Custom404Page

export async function getStaticProps() {
	const { globalData } = await getPageGlobalData()

	return {
		props: {
			page: {
				globalData
			}
		}
	}
}
